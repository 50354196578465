import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import { content as ItemPropType } from 'lib/CustomPropTypes';
import { LinkIfHref } from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import VideoPlayer from 'components/VideoPlayer';
import BadgeLabel from 'components/BadgeLabel';
import DataVizLoader from 'components/DataVizLoader';
import LiveVideoMedia from './LiveVideoMedia';
import { getIs2024ScoreboardEmbed } from '../utils/getIs2024ScoreboardEmbed';

import contentMediaStyles from './styles.module.scss';

const picture = (item, size) => {
  const IMAGE_TRANSFORMATIONS = {
    oneToOne: {
      s: AIMS_FLAVORS.FOCAL_200x200,
      m: AIMS_FLAVORS.FOCAL_200x200,
      l: AIMS_FLAVORS.FOCAL_200x200,
      xl: AIMS_FLAVORS.FOCAL_200x200,
    },
    multiSmall: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_321x214,
      l: AIMS_FLAVORS.FOCAL_300x210,
      xl: AIMS_FLAVORS.FOCAL_350x250,
    },
    multiStandard: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_339x225,
      l: AIMS_FLAVORS.FOCAL_380x250,
      xl: AIMS_FLAVORS.FOCAL_410x290,
    },
    default: {
      s: AIMS_FLAVORS.FOCAL_762x508,
      m: AIMS_FLAVORS.FOCAL_412x275,
      l: AIMS_FLAVORS.FOCAL_500x333,
      xl: AIMS_FLAVORS.FOCAL_1000x667,
    },
  };

  return (
    <LinkIfHref href={item.computedValues.url}>
      <TeasePicture
        className="multiStoryPicture"
        responsiveFlavors={IMAGE_TRANSFORMATIONS[size] || IMAGE_TRANSFORMATIONS.default}
        type={item.type}
        computedValues={item.computedValues}
        hideCredit={size === 'multiSmall'}
      />
    </LinkIfHref>
  );
};

const inlineVideoPlayer = (item, isMutedAutoplayEnabled) => {
  const { item: video, computedValues = {}, metadata } = item;
  const { teaseImage = {} } = computedValues;

  const {
    sublineBadge,
    sublineBadgeText,
    showSublineBadge = false,
    playmakerAutoplayMuted,
    playmakerPlayBehavior,
  } = metadata || {};
  const autoPlay = isMutedAutoplayEnabled ? playmakerPlayBehavior === 'autoplay' : false;

  return (
    <>
      <VideoPlayer
        autoPlay={autoPlay}
        hideVideoTitle
        isEmbedded
        replay
        disableSticky
        onlyAutoplayMuted={playmakerAutoplayMuted ?? true}
        video={{ ...video, teaseImage }}
      />
      {
        metadata.videoSubHeadline?.length
        && (
          <div className={contentMediaStyles['video-subheadline']}>
            <span className={contentMediaStyles['video-subline-link']}>
              {showSublineBadge && (
                <div className={contentMediaStyles['video-subline-badge']}>
                  <BadgeLabel
                    badgeType={sublineBadge}
                    badgeText={sublineBadgeText}
                  />
                </div>
              )}
              <span className={contentMediaStyles['video-subheadline-text']}>
                {metadata.videoSubHeadline}
              </span>
            </span>
          </div>
        )
      }
    </>
  );
};

export function ContentMedia({
  contentItem,
  packageMetadata,
  isLiveBlogLayout,
  mediaSize,
  aspectRatio,
  isLiveVideo,
  uniqueId,
}) {
  const { 'sle-autoplay-muted': isMutedAutoplayEnabled } = useFeatureFlagContext();
  if (!contentItem) return null;
  // only respecting known aspect ratios
  const aspectOneToOne = aspectRatio === '1 / 1';

  const isVideo = contentItem.type === 'video' || isLiveVideo;
  const isDataViz = contentItem.type === 'custom';

  const is2024ScoreboardEmbed = getIs2024ScoreboardEmbed(contentItem);

  const mediaIsImage = () => !(isLiveVideo || isVideo);

  const getMedia = () => {
    // *****************
    // NEED TO LOOK AT THIS. Moved contentItem.type check to top
    // *****************
    if (isLiveVideo === true) {
      return <LiveVideoMedia packageMetadata={packageMetadata} />;
    }
    if (isDataViz) {
      return (
        <DataVizLoader
          data={contentItem}
          uniqueId={uniqueId}
          useResponsiveSizing={!is2024ScoreboardEmbed}
        />
      );
    }

    if (contentItem.type === 'video' && contentItem.metadata.mediaSize !== 'multiSmall') return inlineVideoPlayer(contentItem, isMutedAutoplayEnabled);
    const pictureSize = (aspectOneToOne) ? 'oneToOne' : mediaSize;
    return picture(contentItem, pictureSize);
  };

  const media = getMedia();
  return (
    <div
      className={classNames(
        contentMediaStyles['multistory-media'],
        {
          [contentMediaStyles['aspect-one-to-one']]: aspectOneToOne,
          [contentMediaStyles['storyline-media']]: !isLiveVideo,
          [contentMediaStyles['multistory-small']]: mediaSize === 'multiSmall' && !isVideo,
          [contentMediaStyles['multistory-standard']]: mediaSize === 'multiStandard' && !isVideo,
          [contentMediaStyles['multistory-standard-video']]: mediaSize === 'multiStandard' && isVideo,
          [contentMediaStyles['multistory-small-video']]: mediaSize === 'multiSmall' && isVideo,
          [contentMediaStyles['image-ratio']]: mediaIsImage(),
          [contentMediaStyles['liveblog-layoutMedia']]: isLiveBlogLayout,
          [contentMediaStyles['live-video']]: isLiveVideo,
          [contentMediaStyles['is-flex-item']]: is2024ScoreboardEmbed,
        },
      )}
      data-testid={(isLiveVideo ? 'storyline-media-live' : `storyline-media-${contentItem.type}`)}
    >
      {media}
    </div>
  );
}

ContentMedia.propTypes = {
  contentItem: ItemPropType.isRequired,
  packageMetadata: PropTypes.object,
  isLiveBlogLayout: PropTypes.bool,
  mediaSize: PropTypes.string,
  isLiveVideo: PropTypes.bool,
  aspectRatio: PropTypes.string,
  uniqueId: PropTypes.string,
};

ContentMedia.defaultProps = {
  packageMetadata: {},
  isLiveBlogLayout: false,
  mediaSize: 'multiStandard',
  isLiveVideo: false,
  aspectRatio: '3 / 2',
  uniqueId: null,
};
